.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

.message-box {
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 20em;
  margin: 0.5em 0 1em 0; /* More space between messages */
  border-radius: 0.5em;
  line-height: 1.6; /* Increased line height for better readability */
}

/* Apply different margins to avatars based on the message role */
.avatar-assistant {
  margin-right: 0.75em;
}

.avatar-user {
  margin-left: 0.75em;
}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  display: inline-block;
  background-color: #929292;
  animation: dotFlashing 0.9s infinite alternate;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #929292;
  animation: dotFlashing 0.9s infinite alternate;
}

.dot-flashing::before {
  left: -20px;
  animation-delay: -0.3s;
}

.dot-flashing::after {
  left: 20px;
  animation-delay: 0.3s;
}

@keyframes dotFlashing {
  0%, 100% {
    background-color: #929292;
  }
  50% {
    background-color: #e5e5e5;
  }
}

.message-bubble {
  padding: 10px 15px; /* Adjust the padding to suit the design */
  border-radius: 15px; /* Rounded corners for bubbles */
  margin: 5px 0; /* Space between messages */
  max-width: 80%; /* Maximum width for a message to avoid stretching across the full container */
  word-break: break-word; /* Prevent long words from overflowing */
}

.message-bubble-user {
  background-color: #007bff; /* Example color for the user bubble */
  color: white; /* Text color for the user bubble */
  align-self: flex-end; /* Align to the right in an HStack */
}

.message-bubble-assistant {
  background-color: #f1f1f1; /* Example color for the assistant bubble */
  color: black; /* Text color for the assistant bubble */
  align-self: flex-start; /* Align to the left in an HStack */
}